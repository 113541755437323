import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import styles from './SplitBillScreen.module.scss';
import { useNavigate } from 'react-router-dom';
import { fetchAllTenders } from 'redux/tenders';
import { useDispatch } from 'react-redux';
import { AuthContext } from "context/authContext";
import { useContext } from 'react';
import { useGetActiveLocationWithOrderTab } from "hooks/useTaxHooks";
import { useGetActiveLocation } from "hooks/useGetActiveLocation";
import { splitBill } from 'redux/split';

const SplitBillScreen = () => {
  const dispatch = useDispatch();
  const {currentLocation} = useGetActiveLocation();
  const order = useSelector((state) => state.editOrder.order);
  const { token } = useContext(AuthContext);
  const { checkoutChannel } = useSelector((state) => state.channel);
  const { currency } = useGetActiveLocationWithOrderTab({checkoutChannel});
  // Use orderId and totalAmount from the Redux state
  const orderId = `#${order.order_no}`;
  const totalAmount = order?.total || 75; // Default to 75 if total is not available
  const [splitCount, setSplitCount] = useState(2);
  const [activeTab, setActiveTab] = useState('Equal Split');
  const [showTenderModal, setShowTenderModal] = useState(false);
  const [selectedGuestId, setSelectedGuestId] = useState(null);
  const [guestTenders, setGuestTenders] = useState({});
  const [receivedAmount, setReceivedAmount] = useState(0);
  const [guestStatus, setGuestStatus] = useState({});
  const [customAmounts, setCustomAmounts] = useState({});
const [TENDER_OPTIONS,setTenderOptions] = useState([]);
const [totalTaxAmount, setTotalTaxAmount] = useState(totalAmount);
  const navigate = useNavigate();
// Getting the channel when order type does contain is dine in
useEffect(() => {
  const orderId = `${order?.id}`;
  // Check if orderId is available
  if (orderId) {
    const getTenders = async () => {
      const res = await dispatch(fetchAllTenders({ order_id: orderId }));
      setTenderOptions(res?.payload?.data?.data);
    };
    getTenders();
  } else {

  }
}, [order, dispatch]);

  useEffect(() => {
    const remainingAmount = totalAmount - receivedAmount;
    if (remainingAmount < 0) {
      toast.error('The total received amount exceeds the grand total!');
    }
  }, [receivedAmount, totalAmount]);

  // Add this helper function to calculate amount with tax
  const calculateAmountWithTax = (amount, tender) => {
    if (!tender) return amount;
    
    let taxAmount = 0;
    if (tender.tax_type?.toLowerCase() === 'fixed') {
      taxAmount = tender.tax_value;
    } else if (tender.tax_type?.toLowerCase() === 'percentage') {
      taxAmount = (amount * tender.tax_value) / 100;
    }
    return amount + taxAmount;
  };

  // Update the guests mapping to include tax in displayed amounts
  const guests = Array.from({ length: splitCount }, (_, index) => {
    const baseAmount = activeTab === 'Equal Split' ? totalAmount / splitCount : customAmounts[index + 1] || 0;
    const tender = guestTenders[index + 1];
    const amountWithTax = calculateAmountWithTax(baseAmount, tender);
    
    return {
      id: index + 1,
      amount: baseAmount,
      amountWithTax: amountWithTax,
      status: guestStatus[index + 1] || false
    };
  });

  // Add new state to track temporarily selected tender
  const [tempSelectedTender, setTempSelectedTender] = useState(null);

  // Update handleTenderSelect to only set temporary selection
  const handleTenderSelect = (tenderId) => {
    const selectedTender = TENDER_OPTIONS.find(t => t.id === tenderId);
    setTempSelectedTender(selectedTender);
  };

  // Update handleTenderConfirm to recalculate totals when changing tenders
  const handleTenderConfirm = () => {
    if (tempSelectedTender) {
      // Store old tender for comparison
      const oldTender = guestTenders[selectedGuestId];
      
      // Set new tender
      setGuestTenders(prev => ({
        ...prev,
        [selectedGuestId]: {
          id: tempSelectedTender.id,
          name: tempSelectedTender.name,
          tax_value: tempSelectedTender.tax,
          tax_type: tempSelectedTender.tax_type,
          image: tempSelectedTender.image,
          order_tender_tax: tempSelectedTender.order_tender_tax
        }
      }));

      // Recalculate received and total amounts
      const baseAmount = activeTab === 'Equal Split' 
        ? baseTotal / splitCount 
        : (customAmounts[selectedGuestId] || 0);

      // Calculate old tax amount (if any)
      let oldTaxAmount = 0;
      if (oldTender) {
        if (oldTender.tax_type?.toLowerCase() === 'fixed') {
          oldTaxAmount = oldTender.tax_value;
        } else if (oldTender.tax_type?.toLowerCase() === 'percentage') {
          oldTaxAmount = (baseAmount * oldTender.tax_value) / 100;
        }
      }

      // Calculate new tax amount
      let newTaxAmount = 0;
      if (tempSelectedTender.tax_type?.toLowerCase() === 'fixed') {
        newTaxAmount = tempSelectedTender.tax;
      } else if (tempSelectedTender.tax_type?.toLowerCase() === 'percentage') {
        newTaxAmount = (baseAmount * tempSelectedTender.tax) / 100;
      }

      // Update received amount based on the tax difference
      if (guestStatus[selectedGuestId]) {
        const taxDifference = newTaxAmount - oldTaxAmount;
        if (activeTab === 'Equal Split') {
          setEqualSplitReceived(prev => prev + taxDifference);
        } else {
          setCustomSplitReceived(prev => prev + taxDifference);
        }
      }

      // Recalculate total tax amount
      let totalTax = 0;
      guests.forEach(guest => {
        if (guestStatus[guest.id]) {
          const amount = activeTab === 'Equal Split' 
            ? baseTotal / splitCount 
            : (customAmounts[guest.id] || 0);
          
          const tender = guest.id === selectedGuestId 
            ? tempSelectedTender 
            : guestTenders[guest.id];

          if (tender) {
            if (tender.tax_type?.toLowerCase() === 'fixed') {
              totalTax += tender.tax;
            } else if (tender.tax_type?.toLowerCase() === 'percentage') {
              totalTax += (amount * tender.tax) / 100;
            }
          }
        }
      });

      setTotalTaxAmount(baseTotal + totalTax);
    }
    
    setShowTenderModal(false);
    setTempSelectedTender(null);
  };

  const openTenderModal = (guestId) => {
    setSelectedGuestId(guestId);
    setShowTenderModal(true);
  };

  // Add this state to track the base total without tax
  const [baseTotal, setBaseTotal] = useState(totalAmount);

  // Add this helper function to check if total is already allocated
  const isTotalAllocated = () => {
    const currentTotal = guests.reduce((sum, guest) => {
      return sum + (customAmounts[guest.id] || 0);
    }, 0);
    
    // Allow for small floating-point differences (0.01)
    return Math.abs(currentTotal - baseTotal) < 0.01;
  };

  // Update handleCustomAmountChange
  const handleCustomAmountChange = (guestId, value) => {
    // Check if total is already allocated and this is a new guest with no existing amount
    if (isTotalAllocated() && !customAmounts[guestId]) {
      toast.warn("Cannot add more amounts - total bill has already been allocated", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 2000
      });
      return;
    }

    const numValue = parseFloat(value) || 0;
    
    // Calculate total of all other guests (excluding current guest)
    const otherGuestsTotal = guests.reduce((sum, guest) => {
      if (guest.id === guestId) return sum;
      return sum + (customAmounts[guest.id] || 0);
    }, 0);

    // Calculate maximum allowed amount for this guest
    const maxAllowedAmount = baseTotal - otherGuestsTotal;

    // If new value would exceed total, set to maximum allowed
    const validatedAmount = Math.min(numValue, maxAllowedAmount);

    if (numValue > maxAllowedAmount) {
      toast.warn(`Maximum allowed amount is ${currency} ${maxAllowedAmount.toFixed(2)}`, {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 2000
      });
    }

    setCustomAmounts(prev => ({
      ...prev,
      [guestId]: validatedAmount
    }));


    // Recalculate received amount
    const newReceivedAmount = guests.reduce((sum, guest) => {
      if (!guestStatus[guest.id]) return sum;
      
      const amount = guest.id === guestId ? validatedAmount : (customAmounts[guest.id] || 0);
      const tender = guestTenders[guest.id];
      
      // Calculate base amount plus tax
      let total = amount;
      if (tender) {
        if (tender.tax_type?.toLowerCase() === 'fixed') {
          total += tender.tax_value;
        } else if (tender.tax_type?.toLowerCase() === 'percentage') {
          total += (amount * tender.tax_value) / 100;
        }
      }
      return sum + total;
    }, 0);

    setCustomSplitReceived(newReceivedAmount);
  };

  const areAllGuestsConfirmed = () => {
    return guests.every(guest => {
      // Check if guest is confirmed and has tender selected
      const basicChecks = guestStatus[guest.id] && guestTenders[guest.id];
      
      if (activeTab === 'Custom Split') {
        // For custom split, ensure amount is entered and greater than 0
        const hasValidAmount = customAmounts[guest.id] && customAmounts[guest.id] > 0;
        return basicChecks && hasValidAmount;
      }
      
      return basicChecks;
    });
  };

  const isCustomSplitValid = () => {
    if (activeTab !== 'Custom Split') return true;
    
    const totalCustomAmount = guests.reduce((sum, guest) => {
      return sum + (customAmounts[guest.id] || 0);
    }, 0);
    
    // Allow for small floating-point differences (e.g., 0.01)
    return Math.abs(totalCustomAmount - totalAmount) < 0.01;
  };

  const handleOrderSave = async () => {
    if (!areAllGuestsConfirmed()) {
      if (activeTab === 'Custom Split') {
        toast.error('Please ensure all guests have valid amounts, selected tenders, and are confirmed.');
      } else {
        toast.error('Please confirm all guest payments before proceeding.');
      }
      return;
    }

    if (!isCustomSplitValid()) {
      toast.error('The sum of custom split amounts must equal the total bill amount.');
      return;
    }

    const payload = {
      order_id: order.id,
      grand_total: totalTaxAmount,
      splits: guests.map(guest => ({
        guest_number: guest.id,
        split_amount: guest.amount,
        tender_id: guestTenders[guest.id]?.id || null,
        tender_tax_value: guestTenders[guest.id]?.tax_value || 0,
        tender_tax_type: guestTenders[guest.id]?.tax_type 
          ? guestTenders[guest.id].tax_type.charAt(0).toUpperCase() + 
            guestTenders[guest.id].tax_type.slice(1).toLowerCase()
          : 'Fixed',
        tender_tax_applied: calculateTaxAmount(guest.amount, guestTenders[guest.id]),
        final_amount: guest.amountWithTax,
        tender_name: guestTenders[guest.id]?.name || null,
        payment_status: guestStatus[guest.id] ? "Received" : "Pending"
      }))
    };

    try {
      const result = await dispatch(splitBill({
        payload,
        token,
        locationId: currentLocation.id
      })).unwrap();

      if (result.status === true) {
        navigate("/orders/checkout/receipt");
      }
    } catch (error) {
      toast.error(error || 'An error occurred while saving order split details.');
    }
  };

  // Add new state variables for separate tracking
  const [equalSplitReceived, setEqualSplitReceived] = useState(0);
  const [customSplitReceived, setCustomSplitReceived] = useState(0);

  // Add effect to reset guest status and received amount when switching tabs
  useEffect(() => {
    setGuestStatus({});
    setGuestTenders({});
    if (activeTab === 'Equal Split') {
      setCustomAmounts({});
    }
  }, [activeTab]);

  // Update the useEffect for totalTaxAmount calculation
  useEffect(() => {
    let totalWithTax = baseTotal;
    let totalTax = 0;

    // Calculate total tax from all confirmed guests
    guests.forEach(guest => {
      if (guestStatus[guest.id]) {
        const amount = activeTab === 'Equal Split' 
          ? baseTotal / splitCount 
          : (customAmounts[guest.id] || 0);
        
        const tender = guestTenders[guest.id];
        if (tender) {
          if (tender.tax_type?.toLowerCase() === 'fixed') {
            totalTax += tender.tax_value;
          } else if (tender.tax_type?.toLowerCase() === 'percentage') {
            totalTax += (amount * tender.tax_value) / 100;
          }
        }
      }
    });

    setTotalTaxAmount(totalWithTax + totalTax);
  }, [guestStatus, guestTenders, guests, baseTotal, splitCount, customAmounts, activeTab]);

  // Update the summaryCards section
  const summaryCards = (
    <div className={styles.summaryCards}>
      <div className={styles.card}>
        <span>Received</span>
        <h3>{currency} {(activeTab === 'Equal Split' ? equalSplitReceived : customSplitReceived).toFixed(2)}</h3>
      </div>
      <div className={styles.card}>
        <span>Remaining</span>
        <h3>{currency} {(totalTaxAmount - (activeTab === 'Equal Split' ? equalSplitReceived : customSplitReceived)).toFixed(2)}</h3>
      </div>
      <div className={styles.card}>
        <span>Grand Total</span>
        <h3>{currency} {totalTaxAmount.toFixed(2)}</h3>
      </div>
    </div>
  );

  // Add helper function to calculate tax amount
  const calculateTaxAmount = (amount, tender) => {
    if (!tender) return 0;
    
    let taxAmount = 0;
    if (tender.tax_type?.toLowerCase() === 'fixed') {
      taxAmount = tender.tax_value;
    } else if (tender.tax_type?.toLowerCase() === 'percentage') {
      taxAmount = (amount * tender.tax_value) / 100;
    }
    return taxAmount;
  };

  // Update useEffect for splitCount changes
  useEffect(() => {
    // Clear custom amounts and guest status when split count changes
    setCustomAmounts(prev => {
      const newAmounts = {};
      // Only keep amounts for existing guests
      Object.keys(prev).forEach(guestId => {
        if (parseInt(guestId) <= splitCount) {
          newAmounts[guestId] = prev[guestId];
        }
      });
      return newAmounts;
    });

    setGuestStatus(prev => {
      const newStatus = {};
      Object.keys(prev).forEach(guestId => {
        if (parseInt(guestId) <= splitCount) {
          newStatus[guestId] = prev[guestId];
        }
      });
      return newStatus;
    });

    // Recalculate received amount
    const newReceivedAmount = Array.from({ length: splitCount }, (_, index) => {
      const guestId = index + 1;
      if (!guestStatus[guestId]) return 0;
      const amount = customAmounts[guestId] || 0;
      const tender = guestTenders[guestId];
      return calculateAmountWithTax(amount, tender);
    }).reduce((sum, amount) => sum + amount, 0);
    
    setCustomSplitReceived(newReceivedAmount);
  }, [splitCount]);

  // Update handleCheckboxChange
  const handleCheckboxChange = (guestId) => {
    if (!guestTenders[guestId]) {
      toast.error('Please select a tender method before confirming.');
      return;
    }

    setGuestStatus(prev => {
      const newStatus = { ...prev, [guestId]: !prev[guestId] };
      
      // Recalculate received amount with updated status
      const newReceivedAmount = guests.reduce((sum, guest) => {
        if (!newStatus[guest.id]) return sum;
        
        const amount = activeTab === 'Equal Split' 
          ? baseTotal / splitCount 
          : (customAmounts[guest.id] || 0);
        const tender = guestTenders[guest.id];
        
        let total = amount;
        if (tender) {
          if (tender.tax_type?.toLowerCase() === 'fixed') {
            total += tender.tax_value;
          } else if (tender.tax_type?.toLowerCase() === 'percentage') {
            total += (amount * tender.tax_value) / 100;
          }
        }
        return sum + total;
      }, 0);

      if (activeTab === 'Equal Split') {
        setEqualSplitReceived(newReceivedAmount);
      } else {
        setCustomSplitReceived(newReceivedAmount);
      }
      
      return newStatus;
    });
  };

  return (
    <div className={styles.splitBillContainer}>
      <ToastContainer position="top-right" autoClose={3000} />
      <div className={styles.headerSection}>
        <div className={styles.titleWrapper}>
          <div className={styles.titleSection}>
            <h1>Split Bill</h1>
            <span className={styles.dineInTag}>Dine in</span>
          </div>
          <span className={styles.orderIdText}>Order ID {orderId}</span>
        </div>
      </div>

      <div className={styles.tabContainer}>
        <button 
          className={`${styles.tabButton} ${activeTab === 'Equal Split' ? styles.active : ''}`}
          onClick={() => setActiveTab('Equal Split')}
        >
          Equal Split
        </button>
        <button 
          className={`${styles.tabButton} ${activeTab === 'Custom Split' ? styles.active : ''}`}
          onClick={() => setActiveTab('Custom Split')}
        >
          Custom Split
        </button>
      </div>

      {summaryCards}

      <div className={styles.splitCounterContainer}>
        <div className={styles.splitCounter}>
          <button onClick={() => setSplitCount(prev => Math.max(2, prev - 1))}>−</button>
          <div className={styles.inputContainer}>
            <input 
              type="text" 
              value={splitCount}
              onChange={(e) => setSplitCount(Math.max(2, parseInt(e.target.value) || 2))}
            />
          </div>
          <button onClick={() => setSplitCount(prev => prev + 1)}>+</button>
        </div>
        <p className={styles.splitHint}>This indicates the number of splits for each equal split</p>
      </div>

      <div className={styles.tableContainer}>
        <table className={styles.guestsTable}>
          <thead>
            <tr>
              <th>Guests</th>
              <th>Tenders</th>
              <th>{activeTab === 'Equal Split' ? 'Equal Split' : 'Custom Split'}</th>
              <th>Tax Amount</th>
              <th>Total with Tax</th>
              <th>Confirm Status</th>
            </tr>
          </thead>
          <tbody>
            {guests.map((guest) => {
              const baseAmount = activeTab === 'Equal Split' ? totalAmount / splitCount : customAmounts[guest.id] || 0;
              const tender = guestTenders[guest.id];
              const taxAmount = calculateTaxAmount(baseAmount, tender);
              const totalWithTax = baseAmount + taxAmount;

              return (
                <tr key={guest.id}>
                  <td>Guest {guest.id}</td>
                  <td>
                    <button 
                      className={`${styles.tenderButton} ${tender ? styles.selectedTender : ''}`}
                      onClick={() => openTenderModal(guest.id)}
                    >
                      {tender ? tender.name : 'Select Tender'}
                      <span>→</span>
                    </button>
                  </td>
                  <td>
                    {activeTab === 'Equal Split' ? (
                      `${currency} ${baseAmount.toFixed(2)}`
                    ) : (
                      <div className={styles.inputContainer}>
                        <input
                          type="number"
                          className={`${styles.customAmountInput} ${
                            customAmounts[guest.id] > totalAmount ? styles.hasError : ''
                          }`}
                          value={customAmounts[guest.id] || ''}
                          onChange={(e) => handleCustomAmountChange(guest.id, e.target.value)}
                          placeholder="0.00"
                          min="0"
                          step="0.01"
                        />
                      </div>
                    )}
                  </td>
                  <td>{currency} {taxAmount.toFixed(2)}</td>
                  <td>{currency} {totalWithTax.toFixed(2)}</td>
                  <td>
                    <input 
                      type="checkbox" 
                      className={styles.statusCheckbox} 
                      checked={guest.status}
                      onChange={() => handleCheckboxChange(guest.id)}
                    />
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>

      {showTenderModal && (
        <div className={styles.modal}>
          <div className={styles.modalContent}>
            <h3>Select Tender List</h3>
            <div className={styles.tenderGrid}>
              {TENDER_OPTIONS.map((tender) => {
                // Calculate tax for display
                const baseAmount = activeTab === 'Equal Split' 
                  ? totalAmount / splitCount 
                  : customAmounts[selectedGuestId] || 0;
                
                let taxAmount = 0;
                if (tender.tax_type?.toLowerCase() === 'fixed') {
                  taxAmount = tender.tax;
                } else if (tender.tax_type?.toLowerCase() === 'percentage') {
                  taxAmount = (baseAmount * tender.tax) / 100;
                }

                return (
                  <button
                    key={tender.id}
                    className={`${styles.tenderOption} ${tempSelectedTender?.id === tender.id ? styles.selected : ''}`}
                    onClick={() => handleTenderSelect(tender.id)}
                  >
                    <img className={styles.tenderIcon} src={tender.image} alt={tender.name} />
                    <div className={styles.tenderDetails}>
                      <span>{tender.translations?.en?.name || tender.name}</span>
                      <span className={styles.taxInfo}>
                        {taxAmount > 0 && ` :Tax: (${currency} ${taxAmount.toFixed(2)})`}
                      </span>
                    </div>
                  </button>
                );
              })}
            </div>
            <div className={styles.modalActions}>
              <button 
                className={styles.cancelBtn}
                onClick={() => {
                  setShowTenderModal(false);
                  setTempSelectedTender(null);
                }}
              >
                Cancel
              </button>
              <button 
                className={styles.confirmBtnModal}
                onClick={handleTenderConfirm}
              >
                Confirm
              </button>
            </div>
          </div>
        </div>
      )}

      <div className={styles.actions}>
        <button className={styles.cancelBtn} onClick={() => navigate("/orders/all")}>
          Cancel
        </button>
        <button 
          className={`${styles.confirmBtn} ${!areAllGuestsConfirmed() || !isCustomSplitValid() ? styles.disabled : ''}`}
          onClick={handleOrderSave}
          disabled={!areAllGuestsConfirmed() || !isCustomSplitValid()}
        >
          Confirm
        </button>
      </div>
    </div>
  );
};

export default SplitBillScreen; 