import Icon from "components/Icon/Icon";
import classes from "./PaymentChannelCard.module.scss";

const SplitBillCard = ({ isSelected, onClicked }) => {
  return (
    <li
      className={`
        ${isSelected ? classes.PaymentSplitBillCardSelected : classes.PaymentSplitBillCard}
        ${classes.Disabled}
      `}
      style={{ opacity: 0.5, cursor: 'not-allowed' }}
    >
      {isSelected && <Icon name="tick" />}
      <div className={classes.FlexBox}>
        <div className={classes.ContentWrapper}>
          <span className={classes.Percentage}>%</span>
          <span className={classes.Name}>Split Bill</span>
          <small style={{ color: '#666' }}>(Coming Soon)</small>
        </div>
      </div>
    </li>
  );
};

export default SplitBillCard; 