import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../config/axiosWithLocationV2";

const initialState = {
  splits: [],
  error: null,
  isLoading: false,
  success: false,
  orderSplits: []
};

export const splitBill = createAsyncThunk(
  "split/splitBill",
  async ({ payload, token, locationId }, { rejectWithValue }) => {
    try {
      const response = await axios.post('/order-split-details', payload, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
          'Location': locationId
        }
      });

      if (response.data.status === true) {
        return response.data;
      } else {
        return rejectWithValue(response.data.message || 'Failed to save split details');
      }
    } catch (error) {
      return rejectWithValue(error.response?.data?.message || 'An error occurred');
    }
  }
);

export const fetchOrderSplits = createAsyncThunk(
  "split/fetchOrderSplits",
  async ({ orderId, token, locationId }, { rejectWithValue }) => {
    try {
      const response = await axios.get(`/order-splits/${orderId}`, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
          'Location': locationId
        }
      });

      if (response.data.status === true) {
        return response.data.data;
      } else {
        return rejectWithValue(response.data.message || 'Failed to fetch splits');
      }
    } catch (error) {
      if (error.response?.status === 404) {
        return rejectWithValue('Order splits not found');
      } else if (error.response?.status === 401) {
        return rejectWithValue('Unauthorized access');
      }
      return rejectWithValue(error.response?.data?.message || 'Failed to fetch order splits');
    }
  }
);

const splitSlice = createSlice({
  name: "split",
  initialState,
  reducers: {
    resetSplitState: (state) => {
      state.success = false;
      state.error = null;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(splitBill.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(splitBill.fulfilled, (state, action) => {
        state.isLoading = false;
        state.splits = action.payload.data;
        state.success = true;
      })
      .addCase(splitBill.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })
      .addCase(fetchOrderSplits.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(fetchOrderSplits.fulfilled, (state, action) => {
        state.isLoading = false;
        state.orderSplits = action.payload.splits;
        state.error = null;
      })
      .addCase(fetchOrderSplits.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
        state.orderSplits = [];
      });
  },
});

export const { resetSplitState } = splitSlice.actions;
export default splitSlice.reducer;
